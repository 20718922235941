.scrollArea {
  height: 100%;

  .scrollIndicatorText {
    text-decoration: none;
    font-family: 'IBM Plex Serif', serif;
    color: white;
    font-size: 16px;
    font-weight: 300;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    writing-mode: vertical-rl;
  }
}

.ssdds {
  opacity: 70%;
}
.white {
  color: #FEFDFD;
}