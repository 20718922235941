.navBar {
  margin: 0;
  padding: 0;
}

.menuSpan {
  color: white;
}
.navBarMenu {
  display: flex;
  flex-direction: column;
  transition: 0.1s all;
  gap: 30px;
  a {
    line-height: 20px;
    padding: 2px;
    border: 0;
    user-select: none;
    opacity: 50%;
    cursor: pointer;
    letter-spacing: 0.5px;
  }
}


.focus {
  opacity: 100% !important;
  transition: 0.1s all;
}

.normalFont {
  font-weight: normal;
  overflow: hidden !important;
}

.boldFont {
  font-weight: bold;
}
.projectFont{
    font-family: "nanumsquare", sans-serif;

}
@media (max-width: 750px) {
  .navBarMenu{
    gap: 10px !important;
  >a{
    writing-mode:vertical-lr
  }
  }
}