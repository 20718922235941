.App {
  display: flex;
  width: 100%;
  justify-content: center;
}

.home {
  background-color: black;
}

:root {
}

.layoutMain {
  overflow-x: hidden;
  display: grid;
  transition: 0.5s all;
  width: 100vw;
  grid-template-areas:
    "home"
    "aboutMe"
    "projects"
    "contacts";

  .gridHome {
    grid-area: home;
    height: 100vh;
    width: 100vw;
    z-index: -1;
  }

  .gridAboutMe {
    grid-area: aboutMe;
    width: 100vw;
    z-index: 2;
  }

  .gridProjects {
    grid-area: projects;
    width: 100vw;
    z-index: 2;
  }

  .gridContacts {
    grid-area: contacts;
    width: 100vw;
    z-index: 2;
  }

  .gridRightSpace {
    grid-area: right;
    width: 100vw;
    z-index: 2;
  }
}

.page {
  min-height: 100vh;
}

.float {
  mix-blend-mode: difference;
  z-index: 4;
  position: fixed;
}

.menuBar {
  transform: translateX(-100px);
  opacity: 0;
  animation: moveToX 2s forwards;
  animation-delay: 3s;
  top: 50px;
  left: 50px;
}

.socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto, serif;
  transform: translateY(-100%);
  top: calc(100vh - 30px);
  left: 35px;
  color: white;

  .githubIcon {
    padding-top: 5px;
    opacity: 0;
    animation: showUp 1s forwards;
    animation-delay: 4s;
    width: 25px;
    height: 25px;
    filter: invert(100%);
    mix-blend-mode: difference;
  }

  .socialText {
    transform: translateX(-100px) scale(-1);
    opacity: 0;
    animation: moveToInverse 2s forwards;
    animation-delay: 3s;
    text-decoration: none;
    font-size: 16px;
    writing-mode: vertical-rl;
    padding-top: 5px;


    > a {
      text-decoration: none;
      transform: rotate(0.04deg);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

    }

    > .linkedIn {
      background: linear-gradient(180deg, #0077B1 0%, #C4E0ED 90.7%, #FFFFFF 103.57%, rgba(0, 119, 177, 0) 103.57%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-blend-mode: difference;
      font-weight: normal;
    }

    > .instagram {
      background: linear-gradient(calc(271.22deg + 90deg), #FD1D1D 2.08%, #E1306C 22.64%, #C13584 41.2%, #833AB4 58.25%, #5851DB 84.83%, #405DE6 98.37%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-blend-mode: difference;
      font-weight: normal;
    }

    > .facebook {
      color: #1877F2;
      font-weight: bold;
    }

    > a {
      padding: 5px 0 5px 0;
      text-decoration: none;
    }
  }
}

.progress {
  transform: translateX(100px);
  opacity: 0;
  animation: moveToX 2s forwards;
  animation-delay: 3s;
  top: 50px;
  left: calc(100vw - 50px);
  height: calc(100vh - 85px);
}

@keyframes enableScroll {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

.line {
  width: 100%;
  overflow: hidden;
}

.reverse {
  text-align: right;
}

.gray {
  color: gray;
}

.left {
  transform: translateX(-100%);
}

.right {
  transform: translateX(100%);
}

.up {
  transform: translateY(-100%);
}

.down {
  transform: translateY(100%);
}

@media (max-width: 750px) {
  .menuBar {
    left: 2px !important;
  }
  .progress {
    left: calc(100vw - 22px) !important;
  }
  .socials {
    left: 2px !important;

  }
}
