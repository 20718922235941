.mainLayoutContact {
  height: 100vh;
  font-weight: 700;
  color: var(--bg-color);
  background: var(--primary-color);
  transition: 0.5s color, 0.5s background-color;
  display: flex;
  justify-content: center;
  align-items: center;

  .contactLayout {
    width: inherit;
    height: inherit;
    display: grid;
    grid-template-rows: 1fr min-content 1fr;
    grid-template-columns: 1fr min-content 1fr;
    grid-template-areas:
            ". upper ."
            ". center ."
            ". lower .";

    .upper {
      grid-area: upper;
      display: flex;
      overflow: hidden;
        align-items: end;

    }

    .lower {
      grid-area: lower;
      overflow: hidden;
      display: flex;

    }

    .contactMainContainer {
      width: 100%;
      height: 100%;
      grid-area: center;

      .connectContainer {
        color: white;
        font-family: "roboto", serif;
        font-weight: 300;
      font-size: clamp(3rem, 15vmin, 9rem);
        display: flex;
        flex-direction: column;
        border-top: white solid 3px;
        border-bottom: white solid 3px;
        mix-blend-mode: difference;
        transition: 100ms all;
        margin: 3px 0 3px 0;

        .lets {
          height: clamp(3rem, 15vmin, 9rem);
        }

        .effectContainer {
          display: flex;
          flex-direction: column;
          transition: 0.2s;

          span {
            margin: 0;
            padding: 0;
          }
        }
      }

      .connectContainer:hover {
        border-top: white solid 6px;
        border-bottom: white solid 6px;
        mix-blend-mode: difference;
        margin: 0;
        cursor: pointer;
      }

    }


    .block {
      width: 100%;
      position: relative;
      opacity: 0;
      color: white;
      font-size: clamp(0.8rem, 3vmin, 1.5rem);
      font-weight: 300;
      text-align: center;
      mix-blend-mode: difference;
      transition: 0.2s;
    }

  }
}

.activateAnimation {
  .animation {
    opacity: 1 !important;
    transform: none !important;
    transition: 0s;
  }

  .effectContainer {
    transform: translateY(-50%)
  }
}

.clicked {
  background-color: var(--bg-color) !important;

  .animation {
    transition: 1s !important;
    opacity: 1 !important;
    transform: none !important;
  }


}

a {
  color: inherit;
  text-decoration: none;
}