.mainLayout {
  font-family: "nanumsquare", sans-serif;
  width: 100vw;
  display: flex;
  min-height: 100vh;
  background: var(--bg-color);
  color: var(--primary-color);
  transition: 0.5s color, 0.5s background-color;
  justify-content: center;


  .side {
    grid-area: side;
    min-width: 200px;
  }

  .side1 {
    grid-area: side1;
    min-width: 200px;
  }
}

.projectLayout {
  display: grid;
  width: 100%;
  max-width: 1000px;
  overflow: visible;
  margin: 0 var(--spacing) 0 var(--spacing);
  grid-template-rows: 10vh min-content 30vh min-content 50vh;
  grid-template-areas:
          "."
          "mainProject"
          "."
          "secondaryProject"
          ".";
  justify-content: center;

  .primaryProjectContainer {
    width: calc(100vw - 2 * var(--spacing));
    display: grid;
    max-width: 1000px;
    grid-area: mainProject;
    overflow: visible;
    gap: 20px;

    .primaryProjectText {
      opacity: 0;
      transition: 1s;
      z-index: 2;
      grid-area: text;
      display: grid;
      grid-template-rows: min-content 10px min-content 0 min-content 30px auto;
      grid-template-areas:
      "title"
      "."
      "content"
      "."
      "subContent"
      "."
      "control";

      > span:nth-child(1) {
        font-size: 40px;
        grid-area: title;
      }

      > span:nth-child(2) {
        font-size: clamp(1.1rem, 3.5vw, 2.2rem);
        grid-area: content;
      }

      > span:nth-child(3) {
        font-size: clamp(0.8rem, 3vw, 1.8rem);
        grid-area: subContent;
      }

      > .control {
        display: flex;
        font-size: 26px;
        grid-area: control;

        > .controlButton {
          margin-right: 30px;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        > .controlButton:hover {
          cursor: pointer;

        }
      }

    }

    .primaryProjectSlideContainer {
      opacity: 0;
      transition: 1s;
      height: 100%;
      grid-area: slide;
      overflow: hidden;
      width: 50vw;

      .primaryProjectSlide {
        height: 600px;
        max-width: calc(100% - 30px);
        font-size: clamp(0.8rem, 2vw, 1rem);
        width: clamp(16rem, 45vw, 25rem);
        overflow: visible;
        margin: 0;

        .slide {
          height: min-content;
          border-radius: 30px;
          overflow: visible;

          .cardFront {
            padding: 30px;
            display: flex;
            align-content: center;
            justify-content: center;
            height: clamp(4rem, 20vh, 15rem);
            position: relative;
            border-radius: 30px;
            background-color: #1c1c1c;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 2;
          }

          .cardBack {
            background-color: #181818;
            width: 100%;
            box-sizing: border-box;
            height: min-content;
            padding: 80px 30px 30px 30px;
            position: absolute;
          }

          .cardFront:hover {
            cursor: grab;
          }


        }
      }

    }

  }

  .secondaryProjectContainer {
    overflow: hidden;
    width: 100%;
    grid-area: secondaryProject;
    display: grid;
    grid-template-rows: min-content 50px min-content;
    grid-template-areas: "text" "." "display";

    .secondaryProjectText {
      grid-area: text;
      display: flex;
      flex-direction: column;

      .line:nth-child(1) {
        font-size: 36px;
      }

      .line:nth-child(2) {
        font-size: 27px;
      }
    }

    .secondaryProjectDisplay {
      width: 100%;
      grid-area: display;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
      grid-auto-flow: dense;
      justify-items: stretch;
      place-content: space-between center;
      grid-gap: 20px;

      .secondaryProjectComponent {
        transition: 0.5s all;
        height: inherit;
        overflow: hidden;
        -webkit-backface-visibility: hidden;


        .container {
          box-sizing: border-box;
          height: 100%;
          transition: 0.1s;
          padding: 60px 30px 30px 30px;
          border-radius: 30px;
        }
      }

      .secondaryProjectComponent:hover {
        .container {
          transform: translateY(-10px);
        }
      }
    }
  }

}

.titleStyle {
  font-weight: bolder;
  transform: rotate(0.04deg);
}

.contentStyle {
  font-weight: 500;
  line-height: 120%;
  transform: rotate(0.04deg);
}

.subContentStyle {
  font-weight: 500;
  color: #A18B68 !important;
  transform: rotate(0.04deg);
}

.P100 {
  width: 100%;
  height: 100%;
}

.projectImg {
  height: 100px;
}

.hidden {
  opacity: 0;
}

.activateAnimation {
  .animation {
    opacity: 1 !important;
    transform: none !important;
  }
}

.foo {
  transition: 1s;
}

@media (min-width: 751px) {
}

@media (max-width: 750px) {
}

@media (min-width: 601px) {
  .primaryProjectContainer {
    grid-template-areas: "text slide";
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 600px) {
  .primaryProjectContainer {
    grid-template-areas: "text""slide";
    grid-template-rows: min-content 50%;
  }
  .primaryProjectSlideContainer {
    width: 100% !important;
    overflow: visible !important;
  }
  .primaryProjectSlide {

    font-size: clamp(0.8rem, 3.5vw, 1rem) !important;
  }
}
