.mainLayout {
  font-weight: 700;
  width: 100%;
  min-height: 100vh;
  background: var(--bg-color);
  color: var(--primary-color);
  transition: 0.5s color, 0.5s background-color;
  display: flex;
  justify-content: center;

  .aboutLayout {
    max-width: 1000px;
    margin: 0 var(--spacing) 0 var(--spacing);
    display: grid;
    grid-area: content;
    grid-template-rows: 50vh min-content 50vh;
    justify-items: center;
    grid-template-areas: "." "skills" ".";

    .aboutIntroContainer {
      width: 100%;
      display: flex;
      grid-area: quoteArea;
      flex-direction: column;
      gap: 50px;


      .descriptionContainer {
        grid-area: description;
        text-align: center;
      }

      .titleText {
        font-family: 'IBM Plex Serif', serif;
        font-size: clamp(2.5rem, 6vw, 4rem);
      }

      .titleSubText {
        font-size: clamp(1.3rem, 3vmin, 2rem);
      }

      .quote {
        transition: 1s all;
      }

      .author {
        transition: 1s all;
        text-align: center;
      }

      .description {
        transition: 1s all;
        opacity: 0;
      }
    }

    .skillsContainer {
      max-width: 900px;
      width: inherit;
      display: flex;
      height: min-content;
      grid-area: skills;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      gap: 80px;

      .skillIntro {
        font-family: "IBM Plex Serif", sans-serif;
        font-size: clamp(2.5rem, 6vw, 4rem);
        display: flex;
        font-weight: bolder;

        .skillIntroText {
          transition: 1s;
          color: gray;
        }

        .skillIntroType {
          transition: 1s;
          padding-left: 20px;
        }
      }

      .techStackContainer {
        font-size: clamp(1.3rem, 3vmin, 2rem);
        display: grid;
        grid-template-rows: min-content min-content min-content;
        gap: 10px;
        grid-template-areas:
                "title1 content1"
                "title2 content2"
                "title3 content3";

        .down {
          transition: 1s all;
          overflow: hidden;
        }

        .languageT {
          grid-area: title1;
        }

        .languageC {
          grid-area: content1;
        }

        .frameworkT {
          grid-area: title2;
        }

        .frameworkC {
          grid-area: content2;
        }

        .toolsT {
          grid-area: title3;
        }

        .toolsC {
          grid-area: content3;
        }
      }
    }
  }
}

.activateAnimation {
  .animation {
    opacity: 1 !important;
    transform: none !important;
    transition: 0s;
  }
}
