.mainLayoutHome {
  width: 100vw;
  grid-area: content;
  position: fixed;
  place-items: center;
  background: #111111;
  height: 100vh;
  display: flex;
  color: white;
  transition: 1s all;
  justify-content: center;
}

.homeLayout {
  max-width: 1000px;
  margin: 0 var(--spacing) 0 var(--spacing);
  width: 100%;
  height: 100%;
  display: flex;
  grid-template-rows: 1fr min-content 1fr;
  place-items: center;

  .homeHeader {
    grid-area: header;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .TitleContainer {
      font-size: clamp(2.7rem, 8vw, 5rem);
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .homeHeaderTitleText {
        display: inline
      }

      .shaker {
        width: 50px;
        height: 50px;
        animation: showUp 1s forwards;
        animation-delay: 1s, 1.5s;
        animation-fill-mode: both;

        .homeHeaderTitleIcon {
          background-size: contain;
          width: 100%;
          height: 100%;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .homeHeaderTitleIcon:hover {
          animation: wave 1s forwards;
          animation-delay: 0.1s;
          cursor: pointer;
          animation-iteration-count: 1;
        }
      }
    }

    .content {
      max-width: 700px;
      font-size: clamp(1.3rem, 4vmin, 2rem);
      font-weight: 500;
      font-family: "Inter", serif;
    }
  }
}

@media (max-width: 450px) {
  .TitleContainer{
    flex-direction: column-reverse !important;
  }
}
@media (max-width: 451px) {
  .TitleContainer{
    flex-direction: column-reverse !important;
  }
}
