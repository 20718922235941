.cardInfo {
  width: inherit;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  height: 100%;
  gap: 0.625em;


  a {
    text-decoration: none;
    color: inherit;
    opacity: 0.7;
  }

  a:hover {
    opacity: 1;
  }

  > span:nth-child(1) {

    font-size: 1.25rem;
    grid-area: title;
    padding: 0.625em 0 0.313em 0;
  }

  > span:nth-child(2) {
    font-size: 1rem;
    font-weight: 300 !important;
    grid-area: content;
    padding: 0.313em 0 0.625em 0;
    opacity: 0.7;
  }

  > span:nth-child(3) {
    font-size: 0.75rem;
    grid-area: subContent;
    padding-top: 0.625em;
  }

  .titleContainer {
    line-height: 1.25em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.625em;
    align-items: center;
    //justify-content: space-between;


    .linkContainer {
      font-size: 1.75em;
      display: flex;
      gap: 0.313em;
      width: min-content;
      opacity: 0.8;
    }
  }
  .contentContainer{
    flex-grow: 1;
    padding-bottom: 0.5em;
  }
}

.skillContainer {
  font-size: 0.7em;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.800em;
  row-gap: 4px;

  .skill {
    border-radius: 0.800em;
    opacity: 0.8;
  }

}
.fadeInBefore{
  opacity: 0;
  transition: 1s opacity;
}