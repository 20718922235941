@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@100;200;300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;900&display=swap");

body {
    margin: 0;
    font-family: 'IBM Plex Serif', serif;
    height: 100vh;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

}

:root {
}


html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
    background: #41fffd;
    color: #000;
}

::selection {
    background: #41fffd;
    color: #000;
}


::-webkit-scrollbar {
    display: none
}